import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FeedbackList from './Components/FeedbackList';
import FeedbackStats from './Components/FeedbackStats';
import FeedbackForm from './Components/FeedbackForm';
import Header from './Components/Header';
import AboutPage from './pages/AboutPage';
import AboutIconLink from './Components/AboutIconLink';
import { FeedbackProvider } from './context/FeedbackContext';

function App() {
  return (
    <FeedbackProvider>
      <Router>
        <div>
          <Header />
          <div className='container'>
            <Routes>
              <Route
                exact
                path='/'
                element={
                  <>
                    <FeedbackForm />
                    <FeedbackStats />
                    <FeedbackList />
                  </>
                }
              ></Route>
            </Routes>

            <Routes>
              <Route path='/about' element={<AboutPage />} />
            </Routes>

            <AboutIconLink></AboutIconLink>
          </div>
        </div>
      </Router>
    </FeedbackProvider>
  );
}

export default App;
